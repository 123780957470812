import React from 'react'
import {
  Box,
  Image,
  Link,
  Text,
} from '@chakra-ui/react';

import following_button_icon from '../../images/franchise/following-button-icon.png'



export default function FranchiseSec3() {

  return (
    <Link href={"/franchiseform"}>
      <Box
        position="fixed"
        bottom='20%'
        right={['16px', '64px']}
        zIndex={1}
      >
        <Text
          fontFamily={'extrabold'}
          textAlign={'center'}
          color={'primary.100'}
          textShadow={'0 0 10px white'}
          textTransform={'uppercase'}
        >加盟了解</Text>
        <Image
          src={following_button_icon}
          width={["74px", "122px"]}
          height={["74px", "122px"]}
          boxShadow='base'
          borderRadius={'50%'}
          justifyContent={'center'}
          margin={'0 auto'}
        />
        <Text
          fontFamily={'extrabold'}
          textAlign={'center'}
          color={'primary.100'}
          textShadow={'0 0 10px white'}
          textTransform={'uppercase'}
        >Register Now</Text>
      </Box>
    </Link>
  );
}