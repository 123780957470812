import React from "react";
import {
  Flex,
  VStack,
  Stack,
  Text,
  useBreakpointValue,
  Container,
  Image,
  Tab,
  TabList,
  Tabs,
  TabPanel,
  TabPanels,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  ListItem,
  List,
  ListIcon,
  HStack,
} from "@chakra-ui/react";

import { CheckCircleIcon } from '@chakra-ui/icons'

import franchise_1 from '../../images/franchise/franchise-1.jpg'
import franchise_2 from '../../images/franchise/franchise-2.jpg'
import franchise_3 from '../../images/franchise/franchise-3.jpg'
import franchise_4 from '../../images/franchise/franchise-4.png'
import franchise_5 from '../../images/franchise/franchise-5.jpg'
import franchise_6 from '../../images/franchise/franchise-6.jpg'
import franchise_7 from '../../images/franchise/franchise-7.jpg'
import franchise_8 from '../../images/franchise/franchise-8.jpg'
import franchise_9 from '../../images/franchise/franchise-9.jpg'

import milksha_global from '../../images/franchise/milksha-global.jpg'
import franchiseProcessEN from '../../images/franchise/franchiseProcessEN.jpg'
import franchiseProcessCN from '../../images/franchise/franchiseProcessCN.jpg'






export default function FranchiseSec2() {
  return (
    <Flex w={"full"} fontFamily={"Arial"} py={5} textAlign={"left"}>
      <VStack
        w={"full"}
        justify={"center"}
        align={"center"}
        px={useBreakpointValue({ base: 4, md: 8 })}
      >
        <Stack
          align={"center"}
          spacing={0}
        >
          <Tabs variant="soft-rounded" mt={10} align="center" size="sm" isFitted fontFamily={'extrabold'}>
            <TabList>
              <Stack direction={["column", "row"]}>
                <Tab _selected={{ color: "white", bg: "primary.100" }}>
                  English
                </Tab>
                <Tab _selected={{ color: "white", bg: "primary.100" }}>
                  中文
                </Tab>
              </Stack>
            </TabList>
            <TabPanels>
              <TabPanel>
                {/* <Text textAlign="center" p={5} fontSize={'2rem'}>
                  24-26th Nov FRANCHISE CONFERENCE:
                </Text>
                <Stack
                  maxW={"xl"}
                  align={"center"}
                  spacing={0}
                  mb={[5, 0]}
                >
                  <List spacing={3} textAlign={'left'}>

                    <ListItem fontSize={'md'}><ListIcon as={CheckCircleIcon} color="green.500" boxSize={5} />Brand Description</ListItem>
                    <ListItem fontSize={'md'}><ListIcon as={CheckCircleIcon} color="green.500" boxSize={5} />Cost Structure</ListItem>
                    <ListItem fontSize={'md'}><ListIcon as={CheckCircleIcon} color="green.500" boxSize={5} />Franchise Process</ListItem>
                    <ListItem fontSize={'md'}><ListIcon as={CheckCircleIcon} color="green.500" boxSize={5} />Success Cases</ListItem>

                  </List>
                </Stack> */}
                <Image src={milksha_global} alt="Milksha Global" w={'full'} />
                <Container maxW={'4xl'}>
                  <Text textAlign="center" mb="10" fontSize={'3rem'}>
                    Invest in your future & be your own boss.
                  </Text>
                  <Text textAlign="center" fontSize={'1.8rem'} as="i">
                    Turn your entrepreneurial dreams into reality with the most reasonable investment cost.
                  </Text>
                  <Stack
                    direction={["column", "row"]}
                    justify="center"
                    align="top"
                    spacing={5}
                    p={5}
                    textAlign={'center'}
                    mt="10"
                  >
                    <VStack spacing={6}>
                      <Text fontSize={'1.15rem'} color={'primary.800'}>
                        #International Resources, <br></br>Unlimited Opportunities
                      </Text>
                      <Text fontSize={'1rem'}>
                        Milksha exclusively partnerswith the Jollibee Group,providing excellent supportto make yourentrepreneurship a breezeand open up global markets.
                      </Text>
                    </VStack>
                    <VStack spacing={6}>
                      <Text fontSize={'1.15rem'} color={'primary.800'}>
                        #Infinite R&D, <br></br>Unlock Traffic Secrets
                      </Text>
                      <Text fontSize={'1rem'}>
                        We not only offer limited editionbeverages and collaborationsbut also cover store design,marketing, and productdevelopment, allowing you toeasily seize opportunities.
                      </Text>
                    </VStack>
                    <VStack spacing={6}>
                      <Text fontSize={'1.15rem'} color={'primary.800'}>
                        #Global Support Team, <br></br>Comprehensive Backing
                      </Text>
                      <Text fontSize={'1rem'}>
                        Franchisees enjoy comprehensive support, including store decoration, brand exposure,operational management, product development, raw material procurement, and information helping you succeed effortlessly.
                      </Text>
                    </VStack>
                  </Stack>
                  <Image
                    src={franchise_1}
                    alt="Milksha"
                    mt={10}
                  />
                  <Stack direction={["column", "row"]} mt={10} justifyContent={'space-between'} spacing={5}>
                    <Box maxW={430}>
                      <Image
                        src={franchise_2}
                        alt="Milksha"
                      />
                    </Box>
                    <Box maxW={400}>
                      <Image
                        src={franchise_3}
                        alt="Milksha"
                      />
                    </Box>
                  </Stack>
                  <Stack direction={["column", "row"]} mt={10} justifyContent={'center'} spacing={5}>
                    <Box maxW={[400, 300]}>
                      <Image
                        src={franchise_4}
                        alt="Milksha"
                      />
                    </Box>
                    <Box maxW={[400, 300]}>
                      <Image
                        src={franchise_5}
                        alt="Milksha"
                      />
                    </Box>
                    <Box maxW={[400, 300]}>
                      <Image
                        src={franchise_6}
                        alt="Milksha"
                      />
                    </Box>
                  </Stack>
                  <Stack direction={["column", "row"]} mt={10} justifyContent={'center'} spacing={5}>
                    <Box maxW={[400, 300]}>
                      <Image
                        src={franchise_7}
                        alt="Milksha"
                      />
                    </Box>
                    <Box maxW={[400, 300]}>
                      <Image
                        src={franchise_8}
                        alt="Milksha"
                      />
                    </Box>
                    <Box maxW={[400, 300]}>
                      <Image
                        src={franchise_9}
                        alt="Milksha"
                      />
                    </Box>
                  </Stack>
                  <Image
                    src={franchiseProcessEN}
                    alt="Franchise Process"
                    mt={10}
                  />
                  <Container
                    maxW={'4xl'}
                    fontFamily={'extrabold'}
                    fontSize={'1.3rem'}
                    textAlign={'left'}
                  >
                    <Text m={5}>
                      F&Q
                    </Text>
                    <Accordion allowToggle>
                      <AccordionItem>
                        <h2>
                          <AccordionButton>
                            <Box as="span" flex='1' textAlign='left' fontSize={'1.3rem'}>
                              1.	What is the initial investment needed to open a Milksha store?
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                          The initial investment for a shop depends on a few factors, including store model, the site location, its size and lease terms. The initial investment can range from $300,000 to $500,000 + GST.
                        </AccordionPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <h2>
                          <AccordionButton>
                            <Box as="span" flex='1' textAlign='left' fontSize={'1.3rem'}>
                              2.	How long does the entire process take from franchise application to store opening?
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                          Once the agreement signed, we will start to lock in the site, and process the design and shop fitting, at the same time, your team will complete a 2-3 weeks training. Your cooperation with us will ensure the entire process to be as quick as possible.
                        </AccordionPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <h2>
                          <AccordionButton>
                            <Box as="span" flex='1' textAlign='left' fontSize={'1.3rem'}>
                              3.	Do I need any experience in the F&B industry?
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                          Experience is not necessary, although could be valuable. Milksha team will help you throughout the training and provide manuals and guideline to assist you in operation. The passion and inner drive from the franchisee is always the key element of success.
                        </AccordionPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <h2>
                          <AccordionButton>
                            <Box as="span" flex='1' textAlign='left' fontSize={'1.3rem'}>
                              4.	As a franchisee, do I need to run the business full-time?
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                          For a business to be successful, it is essential that the franchisee is personally involved in all aspects of the business at least at the start of first three months . You must be learning during the training session, and will and able to perform all aspects in operation once the store opens. This include kitchen work, drinks preparation, cashier, and managerial functions, which will require initial significant time investment. But worry not, we are here to assist you throughout, so that your team can be well-trained and gradually develop a reliable store manager so that you could have someone to run the store for you when operation gets steady. It will then be considered a great source of passive income in the long run.
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                  </Container>
                </Container>
              </TabPanel>
              <TabPanel>
                {/* <Text textAlign="center" p={5} fontSize={'2rem'}>
                  11/24-26 加盟說明會內容:
                </Text>
                <Stack
                  maxW={"xl"}
                  align={"center"}
                  spacing={0}
                  mb={[5, 0]}
                >
                  <List spacing={3} textAlign={'left'}>

                    <ListItem fontSize={'md'}><ListIcon as={CheckCircleIcon} color="green.500" boxSize={5} />品牌說明</ListItem>
                    <ListItem fontSize={'md'}><ListIcon as={CheckCircleIcon} color="green.500" boxSize={5} />成本架構</ListItem>
                    <ListItem fontSize={'md'}><ListIcon as={CheckCircleIcon} color="green.500" boxSize={5} />加盟流程</ListItem>
                    <ListItem fontSize={'md'}><ListIcon as={CheckCircleIcon} color="green.500" boxSize={5} />成功案例</ListItem>

                  </List>
                </Stack> */}
                <Image src={milksha_global} alt="Milksha Global" w={'full'} />
                <Container maxW={'4xl'}>
                  <Text textAlign="center" mb="10" fontSize={'3rem'}>
                    投資您的未來，做自己的老闆。
                  </Text>
                  <Text textAlign="center" fontSize={'1.8rem'} as="i">
                    以最合理的投資成本，圓您的創業夢。
                  </Text>
                  <Stack
                    direction={["column", "row"]}
                    justify="center"
                    align="top"
                    spacing={5}
                    p={5}
                    textAlign={'center'}
                    mt="10"
                  >
                    <VStack spacing={6}>
                      <Text fontSize={'1.15rem'} color={'primary.800'}>
                        #國際資源，無限商機
                      </Text>
                      <Text fontSize={'1rem'}>
                        Milksha獨家與Jollibee集團合作，提供卓越支持，讓您輕鬆創業，打開全球市場。
                      </Text>
                    </VStack>
                    <VStack spacing={6}>
                      <Text fontSize={'1.15rem'} color={'primary.800'}>
                        #研發無限，激發流量密碼
                      </Text>
                      <Text fontSize={'1rem'}>
                        我們不僅推出限量飲品和聯名合作，還涵蓋店鋪設計、市場行銷和商品研發，讓您輕鬆掌握商機。
                      </Text>
                    </VStack>
                    <VStack spacing={6}>
                      <Text fontSize={'1.15rem'} color={'primary.800'}>
                        #全球支援團隊，全方位後盾
                      </Text>
                      <Text fontSize={'1rem'}>
                        加盟主享有全方位支援，包括店鋪裝修、品牌曝光、營運管理、商品研發、原物料採購和資訊等，助您輕鬆成功。
                      </Text>
                    </VStack>

                  </Stack>
                  <Image
                    src={franchise_1}
                    alt="Milksha"
                    mt={10}
                  />
                  <Stack direction={["column", "row"]} mt={10} justifyContent={'space-between'} spacing={5}>
                    <Box maxW={430}>
                      <Image
                        src={franchise_2}
                        alt="Milksha"
                      />
                    </Box>
                    <Box maxW={400}>
                      <Image
                        src={franchise_3}
                        alt="Milksha"
                      />
                    </Box>
                  </Stack>
                  <Stack direction={["column", "row"]} mt={10} justifyContent={'center'} spacing={5}>
                    <Box maxW={[400, 300]}>
                      <Image
                        src={franchise_4}
                        alt="Milksha"
                      />
                    </Box>
                    <Box maxW={[400, 300]}>
                      <Image
                        src={franchise_5}
                        alt="Milksha"
                      />
                    </Box>
                    <Box maxW={[400, 300]}>
                      <Image
                        src={franchise_6}
                        alt="Milksha"
                      />
                    </Box>
                  </Stack>
                  <Stack direction={["column", "row"]} mt={10} justifyContent={'center'} spacing={5}>
                    <Box maxW={[400, 300]}>
                      <Image
                        src={franchise_7}
                        alt="Milksha"
                      />
                    </Box>
                    <Box maxW={[400, 300]}>
                      <Image
                        src={franchise_8}
                        alt="Milksha"
                      />
                    </Box>
                    <Box maxW={[400, 300]}>
                      <Image
                        src={franchise_9}
                        alt="Milksha"
                      />
                    </Box>
                  </Stack>
                  <Image
                    src={franchiseProcessCN}
                    alt="Franchise Process"
                    mt={10}
                  />
                  <Container
                    maxW={'4xl'}
                    fontFamily={'extrabold'}
                    fontSize={'1.3rem'}
                    textAlign={'left'}
                  >
                    <Text m={5}>
                      常見問題
                    </Text>
                    <Accordion allowToggle>
                      <AccordionItem>
                        <h2>
                          <AccordionButton>
                            <Box as="span" flex='1' textAlign='left' fontSize={'1.3rem'}>
                              1.	開一家迷客夏需要多少初始投資？
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                          初始投資取決於幾個因素，包括店鋪模型、位置、大小和租賃條款等。初始投資範圍從30萬至50萬澳元加GST。
                        </AccordionPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <h2>
                          <AccordionButton>
                            <Box as="span" flex='1' textAlign='left' fontSize={'1.3rem'}>
                              2.	從加盟申請到店鋪開業需要多長時間？
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                          一旦合同簽署，我們將開始確保店鋪位置，同時啟動設計和裝修流程。與此同時，您的團隊將接受為期2至3周的培訓。您的積極合作將有助於加快整個過程。
                        </AccordionPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <h2>
                          <AccordionButton>
                            <Box as="span" flex='1' textAlign='left' fontSize={'1.3rem'}>
                              3.	我需要在餐飲業擁有經驗嗎？
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                          在餐飲業擁有經驗有幫助但不是必需的。迷客夏團隊將在培訓過程中全程協助您，並提供操作手冊和指南。加盟商的熱情和內在動力一直是成功的關鍵元素。
                        </AccordionPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <h2>
                          <AccordionButton>
                            <Box as="span" flex='1' textAlign='left' fontSize={'1.3rem'}>
                              4.	作為加盟商，我需要全職經營這個業務嗎？
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                          為了使業務成功，至少在開業的最初三個月內，加盟商親自參與業務的各個方面至關重要。您需要在培訓期間學習，並且一旦店鋪開業，將能夠執行所有運營方面的工作，包括廚房工作、飲品製備、收銀和管理功能，這將需要大量時間的投資。但請不用擔心，我們將全程協助您，以便您的團隊接受良好的培訓，逐漸發展出可靠的店鋪經理，從而在業務穩定運營時有人可以代替您經營店鋪。長期而言，這將被視為穩定的被動收入來源。
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                  </Container>
                </Container>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Stack>




      </VStack>

    </Flex>
  );
}
