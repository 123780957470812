import React from 'react'
import {
    Flex,
  } from '@chakra-ui/react';

  import franchise_hero_image from '../../images/franchise/hero_banner_normal.jpg'

  
  
  export default function FranchiseSec1() { 

    return (
      <Flex
        w={"full"}
        h={{base: "220px", md: "515px", lg: "685px", xl: "1000px"}}
        backgroundImage={franchise_hero_image}
        backgroundSize={{base: "cover", md: "contain"}}
        backgroundPosition={"top"}
        backgroundRepeat={"no-repeat"}
        fontFamily={"extrabold"}
      >
      </Flex>
    );
  }